import { i18n } from "../i18n";
import { csrfToken, dataOrNA, debounce } from "../utils";
import { decodePolyline, initMap } from "../../services/map";
import { showNotification } from "../notifications";
import * as Ladda from "ladda";
import { handleTransferResponse, openDeliveryModal } from "./external_deliveries";
import { fitBounds, getMapMarkerByName } from "../leaflet";
import { getDateFormat, getFormattedDate, matchStart, timepickerMeridian } from "../date";
import { isRTL } from "../locale";
import { ARROWHEADS_OPTIONS, POLYLINE_OPTIONS } from "../../constants";

export const openGettModal = async (taskIds, visitIds, onSuccess = () => { window.location.reload() }, onFailure = () => {}) => {
  await openDeliveryModal('/gett/delivery_modal', taskIds, visitIds, () => {
    const modalContainer = $('#modal-container')
    const sourceFields = ['city', 'street', 'number', 'recipient', 'phone']

    sourceFields.forEach(field => {
      $(`#gett-${field}-input`).on('change', (e) => { $(`#gett-${field}`).text(e.currentTarget.value) })
    })

    const map = initGettMap()

    const updateData = debounce(() => { updatePricesAndSource(taskIds, visitIds, map) }, 500)

    updateData()

    $('#gett-product-id').on('change', () => { updateData() })

    handleTransferResponse(modalContainer.find('form'), () => { $('#gett-modal').modal('hide'); onSuccess() }, onFailure)

    $('#gett-datepicker').datepicker({
      format: getDateFormat(),
      language: i18n.t('locale'),
      rtl: isRTL(),
      orientation: 'bottom',
      startDate: getFormattedDate(),
      defaultDate: getFormattedDate(),
      autoclose: true,
      todayHighlight: true,
    }).on('change', () => { updateData() })

    $('#gett-timepicker').timepicker({
      minuteStep: 15,
      showMeridian: timepickerMeridian(),
      icons: {
        up: 'ki ki-arrow-up',
        down: 'ki ki-arrow-down'
      }
    }).on('change', () => { updateData() })

    $('.gett-types-toggle').on('click', (e) => {
      const newType = e.currentTarget.dataset.gettType
      const productIds = ['bba2d4d6-b007-416c-bb55-9de6f9349785', '1331f479-96dc-4422-bea9-59428707da69'] // small route, delivery routes
      const select = $('#gett-product-id')

      $('#gett-delivery-type').val(newType)
      productIds.forEach(option => {
        $(`option[value='${option}']`).toggleClass("d-none", newType === 'asap')
      })
      if (productIds.includes(select.val())) select.val(select.find('option:not(.d-none):first').attr('value'))
      updateData()
    })

    $('#gett-location').select2({
      dir: i18n.t('direction'),
      width: '100%',
      matcher: matchStart
    }).on('change', (e) => {
      const option = e.currentTarget.options[e.currentTarget.selectedIndex];
      sourceFields.forEach(field => {
        $(`#gett-${field}-input`).val(option.dataset[field]).trigger('change')
      })
    })

    modalContainer.find('#gett-modal').modal('show')
    modalContainer.find('#gett-source-modal').on('hidden.bs.modal', () => {
      $('body').addClass('modal-open')
      updateData()
    })

    modalContainer.find('#gett-products-modal').on('hidden.bs.modal', () => {
      $('body').addClass('modal-open')
    })

    setTimeout(() => { map.invalidateSize() }, 200)
  })
}

const updatePricesAndSource = async (taskIds, visitIds, map) => {
  const loader = $('.gett-price-loader')
  const submitBtn = $('.gett-submit')
  loader.removeClass('d-none')
  submitBtn.prop('disabled', true)

  const requestBody = {
    task_ids: dataOrNA(taskIds),
    visit_ids: dataOrNA(visitIds),
    product_id: $('#gett-product-id').val(),
    city: $('#gett-city-input').val(),
    street: $('#gett-street-input').val(),
    number: $('#gett-number-input').val(),
    phone: $('#gett-phone-input').val(),
    recipient_name: $('#gett-recipient-input').val(),
    delivery_type: $('#gett-delivery-type').val(),
    scheduled_date: $('#gett-datepicker').val(),
    scheduled_time: $('#gett-timepicker').val()
  }

  fetch('/gett/estimate_delivery', {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': csrfToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  }).then(res => res.json()).then(data => {
    const { price, currency, source, polyline, tasks_order } = data

    tasks_order.forEach((taskId, index) => {
      $(`.gett-destination-container[data-task-id='${taskId}']`).css('order', index)
    })

    const sourceMarker = getMapMarkerByName(map, 'source')
    sourceMarker.setLatLng(new L.LatLng(source.latitude, source.longitude))

    fitBounds(map)

    $('#gett-price').text(`${price} ${currency}`)

    const oldPolyline = Object.values(map._layers).find(layer => layer.options.name === 'polyline')
    if (oldPolyline) map.removeLayer(oldPolyline)

    if (polyline) {
      L.polyline(decodePolyline(polyline), { ...POLYLINE_OPTIONS, color: 'red', name: 'polyline' }).arrowheads(ARROWHEADS_OPTIONS).addTo(map)
    }

    loader.addClass('d-none')
    submitBtn.prop('disabled', false)
  })
}

const initGettMap = () => {
  const sourceCords = $('#gett-source').val().split(' ');
  const destinationsCoords = $('#gett-destinations').val().split(',')

  return initMap(document.getElementById('gett-map'), (map) => {
    const destinationIcon = L.divIcon({
      className: 'blue-number-icon',
      iconSize: [29, 47],
      iconAnchor: [15, 47]
    })

    const sourceIcon = L.divIcon({
      className: 'red-number-icon',
      iconSize: [29, 47],
      iconAnchor: [15, 47]
    })

    L.marker(sourceCords, { icon: sourceIcon, name: 'source' }).addTo(map)

    destinationsCoords.forEach(coords => {
      L.marker(coords.split(' '), { icon: destinationIcon }).addTo(map)
    })

    fitBounds(map)
  })
}
