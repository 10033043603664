import { i18n } from "./i18n";
import moment from "moment";
import dayjs from "dayjs";

export const getDateFormat = ({ uppercase } = false) => {
  switch (i18n.locale) {
    case 'en-US':
      return uppercase ? 'MM/DD/YYYY' : 'mm/dd/yyyy'
    default:
      return uppercase ? 'DD/MM/YYYY' : 'dd/mm/yyyy'
  }
}

export const dayjsFormatted = date => (dayjs(date, getDateFormat({ uppercase: true })))

export const dayjsFormattedString = date => (date.format(getDateFormat({ uppercase: true })))

export const getDateTimeFormat = () => {
  return i18n.locale === 'en-US' ? 'MM/DD/YYYY hh:mm a' : 'DD/MM/YYYY HH:mm'
}

export const getTimeFormat = () => {
  return i18n.locale === 'en-US' ? 'hh:mm a' : 'HH:mm'
}

export const getFormattedDate = () => {
  const currentDate = new Date()
  const day = currentDate.getDate()
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()

  return day + '/' + month + '/' + year
}

export const matchStart = (params, data) => {
  if (params.term === '') {
    return data
  }

  params.term = params.term ? params.term.replace(/[׳]/, "'") : ''
  const term = params.term.toUpperCase()
  const words = data.text.toUpperCase().split(/[ ,]+/)
  words.push(data.text)

  for (let i = 0; i < words.length; i++) {
    if (words[i].indexOf(term) === 0 || words[i].indexOf(term) != -1) {
      return data
    }
  }
  return false
}

const localeWithCountry = (locale) => {
  switch (locale) {
    case 'en-US':
      return 'en-US'
    case 'he':
      return 'he-IL'
    case 'ar':
      return 'ar'
    default:
      return 'en-GB'
  }
}

export const dateParser = (locale, date) => {
  const res = new Intl.DateTimeFormat(
    localeWithCountry(locale),
    {
      year: 'numeric',
      day: 'numeric',
      month: 'numeric'
    }
  ).format(new Date(date))
  return locale === 'he' ? res.split('.').join('/') : res
}

export const dateTimeParser = (locale, date) => {
  const res = new Intl.DateTimeFormat(
    localeWithCountry(locale),
    {
      year: 'numeric',
      day: 'numeric',
      month: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  ).format(new Date(date))
  return locale === 'he' ? res.split('.').join('/') : res
}

export const dateOrCurrent = (date, withTime = false) => {
  let format = i18n.locale === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  if (withTime) format += i18n.locale === 'en-US' ? ' hh:mm a' : ' HH:mm';

  if (!moment(date, format).isValid() && date !== '') {
    return moment(new Date()).format(format)
  } else if (moment(date, format).isValid()) {
    return moment(date, format).format(format);
  }
}

export const formattedDate = date => {
  const format = i18n.locale === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

  if (!moment(date, format).isValid() && date !== '') {
    return ''
  } else if (moment(date, format).isValid()) {
    return moment(date, format).format(format);
  }
}

export const formattedTime = date => {
  const format = 'HH:mm'

  if (!moment(date, format).isValid() && date !== '') {
    return ''
  } else if (moment(date, format).isValid()) {
    return moment(date, format).format(format);
  }
}

export const timepickerMeridian = () => (i18n.locale === 'en-US')

export const formatDuration = (totalSeconds, full = false) => {
  const durationObj = dayjs.duration(totalSeconds, 'seconds');

  const arr = [
    { key: "days", value: durationObj.days() },
    { key: "hours", value: durationObj.hours() },
    { key: "minutes", value: durationObj.minutes() }
  ]

  const values = arr.filter(el => el.value !== 0)

  if (full) {
    return values.map(el => i18n.t(`datetime.distance_in_words.x_${el.key}`, { count: el.value })).join(' ') || 0;
  } else {
    return values.map(el => `${el.value}${el.key[0]}`).join(' ') || 0;
  }
}
