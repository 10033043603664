import { i18n } from "../i18n";
import { initMap } from "../../services/map";
import { matchStart } from "../date";
import {
  drawServiceMarkers,
  handleTransferResponse,
  openDeliveryModal,
  updateServicePrices
} from "./external_deliveries";

export const openWoltModal = async (taskIds, visitIds, onSuccess = () => { window.location.reload() }, onFailure = () => {}) => {
  await openDeliveryModal('/wolt/delivery_modal', taskIds, visitIds, () => {
    const modalContainer = $('#modal-container')
    const mainModal = modalContainer.find("#wolt-modal")
    mainModal.modal('show')

    $('.wolt-types-toggle').on('click', (e) => {
      const woltType = e.currentTarget.dataset.type
      $('#wolt_delivery_type').val(woltType)
      updatePrices(taskIds, visitIds)
    })

    $(`.wolt-types-toggle[data-type=${$('#wolt_delivery_type').val()}]`).trigger('click')

    const map = initMap(document.getElementById('wolt-map'), () => {})
    setTimeout(() => { map.invalidateSize() }, 200)

    updatePrices(taskIds, visitIds, (data) => { drawServiceMarkers(map, data) })

    $('.scheduled-time-select').on('click', () => {
      updatePrices(taskIds, visitIds)
    });

    ['city', 'street', 'number', 'recipient', 'phone'].forEach(field => {
      $(`#wolt-${field}-input`).on('change', (e) => { $(`#wolt-${field}`).text(e.currentTarget.value) })
    })

    $('#wolt-location').select2({
      dir: i18n.t('direction'),
      width: '100%',
      matcher: (params, data) => matchStart(params, data)
    }).on('change', (e) => {
      const option = e.currentTarget.options[e.currentTarget.selectedIndex];
      ['city', 'street', 'number', 'floor', 'apartment', 'recipient', 'phone', 'notes'].forEach(field => {
        $(`#wolt-${field}-input`).val(option.dataset[field])
      })
    })

    modalContainer.find('#wolt-source-modal').on('hidden.bs.modal', () => {
      $('body').addClass('modal-open')
      updatePrices(taskIds, visitIds, (data) => { drawServiceMarkers(map, data) })
    })

    handleTransferResponse(modalContainer.find('form'), () => { mainModal.modal('hide'); onSuccess() }, onFailure)
  })
}

const updatePrices = async (taskIds, visitIds, onSuccess = () => {}) => {
  await updateServicePrices('wolt', '/wolt/price_estimation', taskIds, visitIds, onSuccess)
}
