import { showNotification } from "../notifications";
import { i18n } from "../i18n";
import { csrfToken, dataOrNA, setPropertyByPath } from "../utils";
import * as Ladda from "ladda";
import { fitBounds, getAllMapMarkers } from "../leaflet";

export const handleTransferResponse = ($el, onSuccess = () => {}, onFailure = () => {}) => {
  const loader = $('.data-preloader')

  $el.on('ajax:success', (e) => {
    const data = e.detail[0]
    showNotification({ message: data.message })
    onSuccess()
  }).on('ajax:error', (e) => {
    const data = e.detail[0]
    showNotification({ message: data.message || i18n.t('shared.something_went_wrong'), type: 'danger' })
    onFailure()
  }).on('ajax:send', () => {
    loader.removeClass('d-none')
  }).on('ajax:complete', () => {
    Ladda.stopAll()
    loader.addClass('d-none')
  })
}

export const openDeliveryModal = async (url, taskIds, visitIds, onLoad = () => {}) => {
  if (!taskIds && !visitIds) {
    showNotification({ message: i18n.t('shared.reselect_tasks_error'), type: 'danger' })
    return
  }

  const modalContainer = $('#modal-container')
  const loader = $('.data-preloader')
  loader.removeClass('d-none')
  const res = await fetch(url,
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken()
      },
      method: 'POST',
      body: JSON.stringify({ task_ids: taskIds, visit_ids: visitIds })
    }
  )

  if (res.ok) {
    const html = await res.text()
    modalContainer.html(html)
    onLoad()
  } else if (res.status === 422) {
    const data = await res.json()
    showNotification({ message: data.errors.join(', '), type: 'danger' })
  } else {
    showNotification({ message: i18n.t('shared.something_went_wrong'), type: 'danger' })
  }

  Ladda.stopAll()
  loader.addClass('d-none')
}

export const updateServicePrices = async (serviceName, url, taskIds, visitIds, onSuccess = () => {}) => {
  const spinner = $(`.${serviceName}-prices-spinner`)
  const pricesContainer = $(`#${serviceName}-prices`)

  spinner.removeClass('d-none')
  pricesContainer.html('')

  const body = {}
  $(`#${serviceName}-form`).serializeArray().forEach(field => { setPropertyByPath(body, field.name, field.value) });

  const res = await fetch(url,{
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken()
    },
    method: 'POST',
    body: JSON.stringify({ ...body, task_ids: dataOrNA(taskIds), visit_ids: dataOrNA(visitIds) })
  })

  const data = await res.json()

  if (data.failed) {
    showNotification({ message: i18n.t('shared.something_went_wrong'), type: 'danger' })
    $(`#${serviceName}-submit`).prop('disabled', true)
  } else {
    $(`#${serviceName}-submit`).prop('disabled', false)
    onSuccess(data)
  }

  pricesContainer.html(data.tasks.map(row => priceRow(row)).join(''))
  spinner.addClass('d-none')
}

const priceRow = (rowData) => (`
  <div class="row align-items-center">
    <div class="col-6 font-size-h5">
      ${i18n.t('models.task.task') } ${rowData.id}:
    </div>
    <div class="col-6 font-size-h5">
      ${rowData.error ? rowData.error : `<span class="font-weight-bolder">${rowData.price} ${rowData.currency_symbol}</span>`}
    </div>
  </div>
`)

export const drawServiceMarkers = (map, data) => {
  getAllMapMarkers(map).forEach((m) => { map.removeLayer(m) })

  const destinationIcon = window.L.divIcon({
    className: 'blue-number-icon',
    iconSize: [29, 47],
    iconAnchor: [15, 47]
  })

  const sourceIcon = window.L.divIcon({
    className: 'red-number-icon',
    iconSize: [29, 47],
    iconAnchor: [15, 47]
  })

  window.L.marker([data.source.latitude, data.source.longitude], { icon: sourceIcon }).addTo(map)

  data.tasks.forEach((t) => {
    window.L.marker([t.dropoff.latitude, t.dropoff.longitude], { icon: destinationIcon }).addTo(map)
  })

  fitBounds(map)
}

