import { Loader } from '@googlemaps/js-api-loader'
import { LatLng } from "leaflet/dist/leaflet-src.esm";
import { i18n } from "../helpers/i18n";

const ZOOM = 15
const DEFAULT_MAP_CENTER = [40.730610, -73.935242]
const DEFAULT_IL_MAP_CENTER = [32.0853, 34.7818]
export const initMap = (mapContainer, callback, options = {}) => {
  if (!mapContainer) return;

  const provider = mapContainer.dataset.provider
  if (provider === 'google') {
    const loader = new Loader({
      apiKey: mapContainer.dataset.mapKey,
      version: 'weekly',
      language: i18n.locale,
      libraries: ['drawing']
    })
    loader.load().then(callback)
  } else if (provider === 'leaflet') {
    if (mapContainer._leaflet_id) mapContainer._leaflet_id = null
    const country = mapContainer.dataset.country
    const map = L.map(mapContainer.id, { fullscreenControl: true, ...options }).setView(defaultMapCenter(country), ZOOM)

    const { url, ...mapParams } = leafletMapParms(country)
    const defaultMap = L.tileLayer(url, { maxZoom: 18, ...mapParams }).addTo(map)

    if (!mapContainer.classList.contains('no-satellite')) {
      const satelliteMap = L.tileLayer('https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=IX0rYv2pA9NPwqEqMEQd')

      mapContainer.insertAdjacentHTML('beforeend', '<div class="map-layer-switch"><i class="fa-light icon-2x fa-satellite"></i></div>')

      $('.map-layer-switch').on('click', () => {
        map.hasLayer(defaultMap) ? defaultMap.removeFrom(map) : defaultMap.addTo(map)
        map.hasLayer(satelliteMap) ? satelliteMap.removeFrom(map) : satelliteMap.addTo(map)
      })
    }

    L.control.scale(gon.scale_units).addTo(map);
    callback(map)
    // hack to set init zoom as 17 for maps which are rendered with max zoom
    if (map.getZoom() === 18) { map.setZoom(17) }
    return map
  }
}

export const leafletMapParms = (country) => {
  if (country === "IL") {
    const MAPA_TOKEN = gon.mapa?.token
    const MAPA_URL = gon.mapa?.url
    const ACCESS_TOKEN = 'pk.eyJ1IjoibGlvbndoZWVsIiwiYSI6ImNrazFsaWozdDBzanoycHJyZDBuOXNhN2MifQ.ytlkjt5fVpSYjG-HwolzxQ'

    return { url: `${MAPA_URL}/tile/{z}/{y}/{x}?token=${MAPA_TOKEN}`, accessToken: ACCESS_TOKEN }
  } else if (gon.organization_id === 5734) {
    return { url: 'https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=IX0rYv2pA9NPwqEqMEQd' }
  } else {
    return { url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png', attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }
  }
}

export const defaultMapCenter = (country = '') => {
  const defaultCenter = country === 'IL' ? DEFAULT_IL_MAP_CENTER : DEFAULT_MAP_CENTER
  if (!gon.default_location) return defaultCenter

  const { default_location: { latitude, longitude } } = gon
  if (!latitude || !longitude) return defaultCenter

  return [latitude, longitude]
}

export const geolocationMapCallback = (lat, long, lat_input_id, long_input_id) => {
  return (map) => {
    const markerIcon = L.divIcon({
      className: 'blue-number-icon',
      iconSize: [29, 47],
      iconAnchor: [15, 47]
    })

    if (!lat || !long) { [lat, long] = defaultMapCenter() }

    if (lat && long) {
      const marker = L.marker([lat, long], { icon: markerIcon }).addTo(map)

      map.setView(marker.getLatLng(), 20)

      map.on('drag zoom', () => {
        const center = map.getCenter()
        marker.setLatLng(center)
        $(lat_input_id).val(center.lat.toFixed(6))
        $(long_input_id).val(center.lng.toFixed(6))
      })

      $(`${lat_input_id}, ${long_input_id}`).on('input', () => {
        const lat = $(lat_input_id).val()
        const lng = $(long_input_id).val()
        const position = new LatLng(lat, lng)
        marker.setLatLng(position)
        map.panTo(position)
      })
    }
  }
}

export const decodePolyline = polyline => {
  const lat_factor = 1e5;
  const lng_factor = 1e5;
  let index = 0;
  let lat = 0;
  let lng = 0;
  const coordinates = [];

  while (index < polyline.length) {
    let d_lat = 0;
    let d_lng = 0;
    let shift = 0;
    let b;

    do {
      b = polyline.charCodeAt(index++) - 63;
      d_lat |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    shift = 0;

    do {
      b = polyline.charCodeAt(index++) - 63;
      d_lng |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    d_lat = ((d_lat & 1) ? ~(d_lat >> 1) : (d_lat >> 1));
    d_lng = ((d_lng & 1) ? ~(d_lng >> 1) : (d_lng >> 1));

    lat += d_lat;
    lng += d_lng;

    coordinates.push([lng / lng_factor, lat / lat_factor]);
  }

  return coordinates;
}
