import { i18n } from "../i18n";
import { initMap } from "../../services/map";
import {
  drawServiceMarkers,
  handleTransferResponse,
  openDeliveryModal,
  updateServicePrices
} from "./external_deliveries";
import { matchStart } from "../date";

export const openGetPackageModal = async (taskIds, visitIds, onSuccess = () => { window.location.reload() }, onFailure = () => {}) => {
  await openDeliveryModal('/get_package/delivery_modal', taskIds, visitIds, () => {
    const modalContainer = $('#modal-container')
    const mainModal = modalContainer.find("#get-package-modal")
    mainModal.modal('show')

    const map = initMap(document.getElementById('get-package-map'), () => {})
    setTimeout(() => { map.invalidateSize() }, 200)

    updatePrices(taskIds, visitIds, (data) => { drawServiceMarkers(map, data) })

    $('.get-package-types-toggle').on('click', (e) => {
      const getPackageType = e.currentTarget.dataset.type
      $('#get_package_delivery_type').val(getPackageType)
      updatePrices(taskIds, visitIds)
    })

    $('#get-package-size').on('change', () => { updatePrices(taskIds, visitIds) })

    ;['city', 'street', 'number', 'recipient', 'phone'].forEach(field => {
      $(`#get-package-${field}-input`).on('change', (e) => { $(`#get-package-${field}`).text(e.currentTarget.value) })
    })

    modalContainer.find('#get-package-source-modal').on('hidden.bs.modal', () => {
      $('body').addClass('modal-open')
      updatePrices(taskIds, visitIds, (data) => { drawServiceMarkers(map, data) })
    })

    modalContainer.find('#get-package-sizes-modal').on('hidden.bs.modal', () => {
      $('body').addClass('modal-open')
    })

    initDateTimeSelects(modalContainer.find('#get-package-express-date-select'), modalContainer.find('#get-package-express-time-select'))
    initDateTimeSelects(modalContainer.find('#get-package-sdd-date-select'), modalContainer.find('#get-package-sdd-time-select'))

    handleTransferResponse(modalContainer.find('form'), () => { mainModal.modal('hide'); onSuccess() }, onFailure)
  })
}

const updatePrices = async (taskIds, visitIds, onSuccess = () => {}) => {
  await updateServicePrices('get-package', '/get_package/price_estimation', taskIds, visitIds, onSuccess)
}

const initDateTimeSelects = ($dateSelect, $timeSelect) => {
  $dateSelect.select2({
    dir: i18n.t('direction'),
    width: '100%',
    matcher: matchStart
  }).on('change', (e) => {
    const option = e.currentTarget.options[e.currentTarget.selectedIndex];

    if ($timeSelect.hasClass("select2-hidden-accessible")) {
      $timeSelect.select2('destroy')
      $timeSelect.empty()
    }

    $timeSelect.select2({
      dir: i18n.t('direction'),
      width: '100%',
      matcher: matchStart,
      data: option.dataset.times.split(',').map(time => ({ id: time, text: time }))
    })
  }).trigger('change')
}
